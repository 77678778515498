<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />

        <DataTable
        v-if="show"
          ref="dt4"
          :value="X_products"
          class="p-datatable-gridlines"
          :rows="10"
          responsiveLayout="scroll"
          style="font-size: 12px"
        >
        

          <Column
            header="Course"
            style="min-width: 3rem;font-weight: bold;"
            :field="columns[0]"
          >
            <template #body="{ data }">
              <b>{{ data.batch }}</b>
            </template>
          </Column>
          <Column
            header="Subject"
            style="min-width: 5rem ;font-weight: bold;"
            :field="columns[1]"
          >
            <template #body="{ data }">
              <b>{{ data.subject }}</b>
            </template>
          </Column>
          <Column
            header="Teacher Name"
            style="min-width: 5rem ;font-weight: bold;"
            :field="columns[6]"
          >
            <template #body="{ data }">
              {{ data.teacheR_NAME }}
            </template>
          </Column>
          <Column header="Schedule" style="min-width: 5rem ;font-weight: bold;" :field="columns[6]">
            <template #body="{ data }">
              {{ data.schedule }}
            </template>
          </Column>
          <Column
            header="Chapter / Topics"
            style="min-width: 5rem ;font-weight: bold;"
            :field="columns[6]"
          >
            <template #body="{ data }">
              {{ data.discuessioN_ON_TOPIC }}
            </template>
          </Column>
          <Column header="View" :field="columns[2]"  style="min-width: 5rem ;font-weight: bold;">
            <template #body="{ data }">
              <Button label="View" class="p-button-outlined p-button-success" @click="view(data)" />
            </template>
          </Column>
          <Column header="Batch Year" :field="columns[4]"  style="min-width: 5rem ;font-weight: bold;">
            <template #body="{ data }">
              {{ data.batcH_YEAR }}
            </template>
          </Column>
        </DataTable>
      </div>
      <!-- ------------------------------------------------------------------------------------------------- -->

       <div class="card">
        <Toast />

        <DataTable
          ref="dt4"
          :value="products"
          class="p-datatable-gridlines"
          :rows="10"
          responsiveLayout="scroll"
          style="font-size: 12px"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <h5 class="m-0"><b> <u>Test Topics</u></b></h5>
              <Button icon="pi pi-refresh" @click="get_list()" />
            </div>
          </template>

          <Column
            header="Batch Year"
            style="min-width: 2rem;"
            :field="columns[0]"
          >
            <template #body="{ data }">
              {{ data.batcH_YEAR }}
            </template>
          </Column>
          <Column
            header="Test Date"
            style="min-width: 7rem ;"
            :field="columns[1]"
          >
            <template #body="{ data }">
              {{ data.tesT_DATE }}
            </template>
          </Column>
          <Column
            header="Mathematics	Physics	Chemistry"
            style="min-width: 5rem ;"
            :field="columns[6]"
          >
            <template #body="{ data }">
              {{ data.m_TOPIC }}
            </template>
          </Column>
          <Column header="Physics" style="min-width: 5rem ;" :field="columns[6]">
            <template #body="{ data }">
              {{ data.p_TOPIC }}
            </template>
          </Column>
          <Column
            header="Chemistry"
            style="min-width: 5rem ;"
            :field="columns[6]"
          >
            <template #body="{ data }">
              {{ data.c_TOPIC }}
            </template>
          </Column>
          <Column header="Botany" :field="columns[2]"  style="min-width: 5rem ;">
            <template #body="{ data }">
              {{ data.b_TOPIC }}
            </template>
          </Column>
          <Column header="Zoology" :field="columns[4]"  style="min-width: 5rem ;">
            <template #body="{ data }">
              {{ data.z_TOPIC }}
            </template>
          </Column>
        </DataTable>
      </div>


      <Sidebar
          v-model:visible="X_productDialog"
          :baseZIndex="1000"
          position="full"
        >
        <div class="col-12">
            <div class="card">
              <h5>Form on Today's Lecture </h5>
              <hr />
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-3">
                  <label for="schedule">schedule</label>
                  <InputText
                  type="text"
                  id="inputtext"
                  required="true"
                  disabled
                  autofocus
                  v-model="XP_products.schedule"
                />
                </div>
                <div class="field col-12 md:col-3">
                  <label for="teachername">Teacher name</label>
                  <InputText
                  type="text"
                  id="inputtext"
                  required="true"
                  disabled
                  autofocus
                  v-model="XP_products.teacheR_NAME"
                />
                </div>
                <div class="field col-12 md:col-6">
                  <label for="Topic">Topic</label>
                  <InputText
                  type="text"
                  id="inputtext"
                  required="true"
                  disabled
                  autofocus
                  v-model="XP_products.discuessioN_ON_TOPIC"
                />
                </div>
                </div>


              </div>
              </div>

              <div class="col-12">
            <div class="card">
              <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-6">
                  <div style="padding:56.25% 0 0 0;position:relative;"><iframe :src=XP_products.link frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
                </div>
                <div class="field col-12 md:col-6">
                  <iframe :src=XP_products.chat width="100%" height="500px" frameborder="0"></iframe>
                </div>
              </div>
              </div>
              </div>



        </Sidebar>


      
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      XP_products:[],
      show:false,
      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      file_attachment: "",
      dropdownItems: [
        { name: "Motivational", value: "Motivational" },
        { name: "Interview", value: "Interview" },
      ],
      dropdownItems2: [
        { name: "Active", value: "Active" },
        { name: "InActive", value: "InActive" },
        { name: "Deleted", value: "Deleted" },
      ],
      products: null,
      X_products:null,
      productDialog: false,
      X_productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus: { name: "Active", value: "Active" },
      selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course",
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    this.get_list();
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    //a simple date formatting function

    view(data)
    { this.XP_products=[];
      this.XP_products=data;
      this.X_productDialog = true;
    },

    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      var data = {
        Id: localStorage.getItem("id"),
      };
      this.loading = true;
      var promise = apis.dashboarddataA(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);

        this.totalRecords = 0;
        //alert(this.totalRecords);
      });

      console.log(data);
    },
    get_list: function () {
      var data = {
        Id: localStorage.getItem("id")
      };
      this.loading = true;
      var promise = apis.dashboarddataA(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.products = response.data.assteacherdashboardtestdata;
        if (response.data.assteacherdashboardlecturedata) {
          this.X_products = response.data.assteacherdashboardlecturedata;
          this.show=true;
        }
        
        console.log(this.products[0]);
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
      });

      console.log(data);
    },

    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },

    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },

    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deleteVideo() {
      if (this.product.id) {
        var data = {
          Id: this.product.id,
        };
        this.isLoadingModel = true;
        var promise = apis.deletebatchmaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.status == 200) {
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
